$(document).ready(function () {
  /**
   * Exists
   */

  jQuery.fn.exists = function () {
    return this.length > 0;
  };

  /**
   * Smooth scroll
   */

  smoothScroll.init({
    speed: 1000,
  });

  /**
   * Header video
   */

  $(".header-video").each(function (i, elem) {
    headerVideo = new HeaderVideo({
      element: elem,
      media: ".header-video__media",
    });
  });

  // Open nav

  $("header .button.hamburger").on("click", function (e) {
    e.preventDefault();
    $("body").addClass("navigation-is-open");
    $(".primary-navigation").removeClass("hidden").addClass("animated fadeIn");
  });

  $("header .button.search").on("click", function (e) {
    e.preventDefault();
    $("body").addClass("navigation-is-open");
    $(".search-form").removeClass("hidden").addClass("animated fadeIn");
  });

  // Close nav

  $("nav.primary  .close").on("click", function (e) {
    e.preventDefault();
    $("body").removeClass("navigation-is-open");
    $(".primary-navigation").addClass("hidden").removeClass("animated fadeIn");
    $(".search-form").addClass("hidden").removeClass("animated fadeIn");
  });

  /**
   * Isotope - News, Events and Social
   */

  // need if exists here to load only when needed
  // $nes_container = $('.speakeasy .grid');

  // $nes_container.socialfeed({
  //     fb_token: 'EAASEfpcdtycBAKnP7LAyqgd2yLUGvyYLfRfV0tgPAGxJNGw1reDoKZADm1oStfZBNFqZBnAQ0YhCempLZAGBSZAeT8s0pBdc6dqzD8nPOjoxiFvxSkVZCe0rgEfu23dLM3xMJZCEpzZAQbAV0qd6PZAgapuQmyNZC9HRZBADOEQRKJ0cAZDZD',
  //     fb_username: 'SDCreative.co',
  //     tw_username: '_SDCreative',
  //     local_news_url: '/wp-json/wp/v2/posts/',
  //     instagram_username: 'sdcreative',
  //     fb_limit: 10,
  //     tw_limit: 10,
  //     local_news_limit: 10,
  //     instagram_limit: 10,
  //     global_limit_full: 40,
  //     global_limit_mini: 20
  // });

  // $nes_container.isotope({
  //     itemSelector: '.grid-item',
  //     masonry: {
  //         columnWidth: '.grid-sizer'
  //     },
  //     stagger: 30,
  //     hiddenStyle: {
  //         opacity: 0
  //     },
  //     transitionDuration: '0.2s',
  //     visibleStyle: {
  //         opacity: 1
  //     },
  //     filter: '*',
  //     getSortData : {
  //         timestamp : function ( $elem ) {
  //             return parseInt( $($elem).data('timestamp'), 10 );
  //         }
  //     },
  //     sortBy : 'timestamp',
  //     sortAscending : false,
  // });

  // var $nes_checkboxes = $('#filters input');

  // $nes_checkboxes.change( function() {
  //     var inclusives = [];
  //     $nes_checkboxes.each( function( i, elem ) {
  //         if ( elem.checked ) {
  //             inclusives.push( elem.value );
  //         }
  //     });
  //     var filterValue = inclusives.length ? inclusives.join(', ') : '*';
  //     $nes_container.isotope({ filter: filterValue });
  //     if(inclusives.length === 0){
  //         $('input#all').attr('checked', true);
  //     }
  // });

  // var filterCheckboxes = {
  //     init: function() {
  //         this.bindUi();
  //         $('input#all').attr('checked', true);
  //     },
  //     bindUi: function(){
  //         $(document)
  //         .on("click", $nes_checkboxes.selector, function(e) {
  //             var field = $(this).attr('id');
  //             filterCheckboxes.checkNumberSelected(field,e);
  //             filterCheckboxes.checkSelected(field);
  //             $(this).parent().toggleClass('active');
  //         });
  //     },
  //     checkNumberSelected: function(field,e){
  //         var numChecked = $($nes_checkboxes.selector+':checked').length;
  //         if(numChecked <= 0){
  //             e.preventDefault();
  //             return false;
  //         }
  //     },
  //     checkSelected: function(field){
  //         if(field == "all"){
  //             $nes_checkboxes.not($('input#all')).attr('checked', false);
  //         }
  //         if(field != "all"){
  //             $('input#all').attr('checked', false);
  //             $('input#'+field).attr('checked', true);
  //         }

  //     }
  // };

  // if($nes_container.exists()){
  //     filterCheckboxes.init();
  // }

  // Carousels - Settings

  prevArrow = '<a href="#" class="button dot arrow-left"></a>';
  nextArrow = '<a href="#" class="button dot arrow-right"></a>';

  slideSpeed = 1000;
  cssEasing = "cubic-bezier(0.215, 0.610, 0.355, 1.000)";
  jsEasing = "easeOutCubic";
  dotsClass = "slick-dots";

  /**
   * Carousel
   */

  $(".carousel.carousel-chums").slick({
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    mobileFirst: true,
    preloader: false,
    infinite: true,
    arrows: true,
    dots: true,
    fade: false,
    speed: slideSpeed,
    cssEase: cssEasing,
    easing: jsEasing,
    useCSS: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    touchMove: true,
    swipe: true,
    draggable: true,
    accessibility: false,
    adaptiveHeight: false,
    dotsClass: dotsClass,
    responsive: [
      { breakpoint: 0, settings: { slidesToShow: 1, slidesToScroll: 1 } },
      { breakpoint: 500, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 700, settings: { slidesToShow: 3, slidesToScroll: 3 } },
      { breakpoint: 900, settings: { slidesToShow: 4, slidesToScroll: 4 } },
    ],
  });

  // Load job list

  var request = "/wp-json/wp/v2/cpt-job/";
  var per_page = $(".job-list-items").data("per-page");

  var jobList = {
    init: function () {
      jobList.bindUi();
      jobList.receiveJobsFromServer();
      jobList.setJobListPagination();
      jobList.setFirstCategory();
      jobList.setFirstPage();
    },
    bindUi: function () {
      $("body").on("click", ".filter-job-categories a", function (e) {
        e.preventDefault();
        if (!$(this).hasClass("active")) {
          var slug = $(this).data("slug");
          request = "/wp-json/wp/v2/cpt-job?filter[tax_job]=" + slug;
          jobList.receiveJobsFromServer();
          var total_jobs = $(this).data("num-jobs");
          jobList.setJobListPagination(total_jobs);
          jobList.hidePagination();
          jobList.setFirstPage();
          jobList.toggleActive($(this));
        }
      });
      $("body").on("click", ".pagination a", function (e) {
        e.preventDefault();
        if (!$(this).hasClass("active")) {
          var slug = $(".filter-job-categories a.active").data("slug");
          var page = $(this).data("page");
          var offset = (page - 1) * per_page;
          request =
            "/wp-json/wp/v2/cpt-job?filter[tax_job]=" +
            slug +
            "&offset=" +
            offset;
          jobList.receiveJobsFromServer();
          var total_jobs = $(".filter-job-categories a.active").data(
            "num-jobs"
          );
          jobList.setJobListPagination(total_jobs);
          $(".pagination li:nth-child(" + page + ") a").addClass("active");
        }
      });
    },
    setFirstCategory: function () {
      $(".filter-job-categories li:first-child a").addClass("active");
    },
    setFirstPage: function () {
      $(".pagination li:first-child a").addClass("active");
    },
    hidePagination: function () {
      // $('.job-list-items').hide();
      // $('.pagination').hide();
    },
    showPagination: function () {
      // $('.job-list-items').show();
      // $('.pagination').show();
    },
    toggleActive: function (el) {
      //console.log(el.parent().parent().find('a'));
      el.parent().parent().find("a").removeClass("active");
      el.addClass("active");
    },
    getJobTaxonomy: function (job) {
      num = 0;
      if (job.pure_taxonomies.tax_job) {
        num = job.pure_taxonomies.tax_job.length;
      }
      var job_tax_ids = "";
      for (var i = 0; i < num; i++) {
        job_tax_ids += job.pure_taxonomies.tax_job[i].name;
      }
      return job_tax_ids;
    },
    receiveJobsFromServer: function () {
      var target = $(".job-list .job-list-items");
      target.addClass("loading-jobs");
      url = request;
      $.ajax({
        url: url,
        type: "GET",
        data: { per_page: per_page },
        success: function (result) {
          var num = result.length;
          var html = "";
          for (var i = 0; i < num; i++) {
            html +=
              '<a href="' +
              result[i].link +
              '" class="job-list-item ' +
              result[i].acf.job_filled +
              '" data-mh="job">';
            html +=
              '<div class="job-list-title">' +
              result[i].acf.job_name +
              "</div>";
            html +=
              '<div class="job-list-salary">' +
              result[i].acf.job_salary +
              "</div>";
            html +=
              '<div class="job-list-location">' +
              result[i].acf.job_location +
              "</div>";
            html +=
              '<img class="red" src="/assets/images/job-filled-red.png" />';
            html +=
              '<img class="white" src="/assets/images/job-filled-white.png" />';
            html += "</a>";
          }
          target.html(html);
          $(".job-list-item").matchHeight();
          target.removeClass("loading-jobs");
          jobList.showPagination();
        },
      });
    },
    setJobListPagination: function (total_jobs) {
      var target = $(".job-list-items");
      if (total_jobs === undefined) {
        total_jobs = target.data("total-jobs");
      }
      var num_pages = Math.ceil(total_jobs / per_page);
      var target2 = $(".pagination");
      var html = "";
      html += '<ul class="inline-group">';
      for (var i = 0; i < num_pages; i++) {
        var page = i + 1;
        html += '<li class="inline-group-item">';
        html +=
          '<a href="#" class="button rect" data-page="' +
          page +
          '">' +
          page +
          "</a>";
        html += "</li>";
      }
      html += "</ul>";
      target2.html(html);
    },
  };
  if ($(".job-list-items").exists()) {
    jobList.init();
  }
});
